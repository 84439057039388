import React from "react";
import Field from "./field.js";

/*const Time = (props) => {
  return (<fieldset className="group">
    <legend>{ props.label }</legend>
    <div>
      {
        props.inputs.map((input, i) => {
          let max = 59;
          if (i === 0) {
            max = parseInt(props.timeFormat);
          }
          const Tag = i === 2 ? Select : NumberField;
          return (<Tag
            {...props}
            {...input}
            min={ i === 2 ? undefined : 0 }
            max={ i === 2 ? undefined : max }
            type={ i === 2 ? "select" : "number" }
            choices={ i === 2
              ? [
                  { value: "AM", text: "AM" },
                  { value: "PM", text: "PM" },
                ]
              : undefined
            }
          />);
        })
      }
    </div>
  </fieldset>);
};

export default Time;
*/
const Time = (props) => {
  const validationFunction = (value) => {
    if (value.trim() === "") {
      return false;
    }
    return true;
  };
  return (<Field {...props} type="time" validationFunction={ validationFunction } />);
};

export default Time;
